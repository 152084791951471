<template>
  <v-row v-if="profile">
    <v-col cols="12" md="6">
      <MyActions v-if="authUser && authUser.id == profile.user_id" />
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <ReportUser :profile="profile" />
      </v-card-actions>
      <v-card class="mt-14 mb-6 text-center" v-if="profile">
        <v-avatar
          size="128"
          class="rounded-circle elevation-6 mt-n12 d-inline-block anim-shine"
        >
          <v-img :src="profile.avatar" />
        </v-avatar>
        <v-badge
          v-if="!['student', 'member'].includes(profile.role)"
          overlap
          bordered
          bottom
          offset-x="75"
          offset-y="-25"
          color="green accent-4 white--text"
        >
          <template v-slot:badge>
            <UserBadge :user="profile" />
          </template>
        </v-badge>
        <v-card-text class="text-center">
          <h4 class="text-h4 mb-3 text--primary">
            {{ profile.display_name.ucWords() }}
          </h4>

          <h5>Joined {{ profile.created_at | fromNow }}</h5>
          <p class="text--secondary" v-html="profile.about"></p>
        </v-card-text>
      </v-card>
      <continue-attempts v-if="authUser && authUser.id == profile.user_id" />
    </v-col>
    <v-col cols="12" md="6">
      <v-tabs
        v-model="tabIdx"
        center-active
        next-icon="mdi-arrow-right"
        prev-icon="mdi-arrow-left"
        show-arrows
        grow
      >
        <v-tab>
          {{ $lang("Stats") }}
        </v-tab>
        <v-tab v-if="!['student'].includes(profile.role)">
          {{ $lang("Course") }}
        </v-tab>
        <v-tab>
          {{ $lang("Feed") }}
        </v-tab>
        <v-tab>
          {{ $lang("PurchasedMocktests") }}
        </v-tab>
        <v-tab>
          {{ $lang("Attempts") }}
        </v-tab>
      </v-tabs>
      <div class="FixedHeightContainer">
        <div class="Content">
          <v-tabs-items v-model="tabIdx">
            <v-tab-item>
              <tab-stats :user_id="profile.user_id" />
            </v-tab-item>
            <v-tab-item v-if="!['student'].includes(profile.role)">
              <tab-course :profile="profile" />
            </v-tab-item>
            <v-tab-item>
              <tab-feed :profile="profile" />
            </v-tab-item>
            <v-tab-item>
              <tab-purchased-mocktests :user_id="profile.user_id" />
            </v-tab-item>
            <v-tab-item>
              <tab-attempts :user_id="profile.user_id" />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </div>
    </v-col>
  </v-row>
  <v-container
    v-else
    class="fill-height d-flex flex-column justify-center align-center"
  >
    <v-progress-linear indeterminate></v-progress-linear>
    <div class="subtitle my-4">{{ $lang("pleaseWait") }}</div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { axios } from "@/plugins/axios";
import localforage from "@/plugins/localforage";
import TabStats from "./TabStats";
import TabPurchasedMocktests from "./TabPurchasedMocktests";
import TabAttempts from "./TabAttempts";
import TabFeed from "./TabFeed";
import TabCourse from "./TabCourse";
import MyActions from "./MyActions.vue";
import ContinueAttempts from "../ContinueAttempts.vue";
import UserBadge from "@/components/UserBadge";
import ReportUser from "@/app/ugcp/modules/ReportUser";
export default {
  name: "profile",
  components: {
    TabStats,
    TabFeed,
    TabCourse,
    ReportUser,
    UserBadge,
    TabPurchasedMocktests,
    TabAttempts,
    ContinueAttempts,
    MyActions,
  },
  data() {
    return {
      profile: null,
      tabIdx: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  watch: {},
  methods: {
    fetchProfile(identity) {
      return axios
        .get("social/profiles/" + identity)
        .then((res) => {
          this.profile = res.data;
        })
        .catch((err) => {
          this.$router.replace({ name: "home" });
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.fetchProfile(this.$route.params.id);
    } else {
      setTimeout(() => {
        if (this.authUser) {
          this.$router.replace({
            name: "profile",
            params: { id: this.authUser.username },
          });
        } else {
          this.$router.replace({ name: "home" });
        }
      }, 300);
    }
  },
};
</script>

<style>
.FixedHeightContainer {
  height: 80vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}

/*SCROLLBAR MODIFICATIONS*/

.FixedHeightContainer::-webkit-scrollbar {
  width: 0px;
}
/* 
.FixedHeightContainer::-webkit-scrollbar-thumb {
  background: #dddddd;
  border-radius: 8px;
}
.FixedHeightContainer::-webkit-scrollbar-track {
  background: #ffffff; 
} */
</style>
