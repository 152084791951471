<template>
  <v-row v-if="authUser && authUser.id">
    <v-col cols="12" class="d-flex my-4">
      <v-chip
        @click="
          () => {
            $router.push({
              name: 'products',
            });
          }
        "
        small
        class="ma-0 pa-0 px-1 anim-shine"
        color=""
        outlined
      >
        <v-img
          width="20"
          src="/img/coins/coin-single-50.png"
          class="mr-1"
        ></v-img>
        <span class="subtitle-1">{{
          authUser.wallet ? authUser.wallet.coins : "0"
        }}</span>
        <v-icon class="ml-0">mdi-plus</v-icon>
      </v-chip>
      <!-- 🌕{{ authUser.wallet ? authUser.wallet.coins : "0" }} Coins -->
      <v-spacer></v-spacer>
      <v-btn
        @click="
          () => {
            $router.push({
              name: 'update-profile',
            });
          }
        "
        small
        outlined
        text
        class="rounded-xl"
      >
        {{ $lang("update") + " " + $lang("profile") }}
        <v-icon right>mdi-account</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
};
</script>