<template>
  <v-card
    color="transparent"
    elevation="0"
    :id="getElementID"
    class="d-flex py-5 mx-2"
  >
    <div class="grey--text d-flex flex-row text-left">
      <v-badge
        fixed
        top
        left
        v-if="
          authUser &&
          ['superadmin', 'admin', 'manager', 'moderator'].includes(
            authUser.role
          ) &&
          !item.user.verified
        "
        dot
        color="red"
      >
      </v-badge>

      <v-avatar size="44" class="mr-4">
        <v-img
          @click.stop="
            $router.push({
              name: 'profile',
              params: { id: item.user.username },
            })
          "
          :src="item.user ? item.user.avatar : ''"
          :key="item.user ? item.user.avatar : item.id"
          alt="avatar"
          class="image"
        />
      </v-avatar>
      <div>
        <div class="body-1">
          {{ item.user ? item.user.display_name : "user" }} has joined.
        </div>
        <div
          :class="
            $store.getters['isThemeDark']
              ? `caption grey--text text--darken-2`
              : `caption grey--text text--lighten-1`
          "
        >
          {{ item.created_at | fromNow }}
        </div>
      </div>
    </div>
    <v-spacer></v-spacer>
    <v-btn
      @click.stop="
        $router.push({
          name: 'profile',
          params: { id: item.user.username },
        })
      "
      icon
      v-if="true"
      ><v-icon>mdi-arrow-right</v-icon></v-btn
    >
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { downloadDataURL } from "@/helpers/utils";
import moment from "@/plugins/moment";
import { Device } from "@capacitor/device";

export default {
  components: {},
  props: ["item"],
  data() {
    return {
      info: null,
    };
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
    getElementID() {
      return "card-" + this.item.id;
    },
  },
  methods: {
    // fromNow(strTime) {
    //   return moment(strTime).fromNow();
    // },
    async print() {
      const el = document.getElementById(this.getElementID);
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: "dataURL",
        useCORS: true,
        backgroundColor: "rgba(0,0,0,0)",
      };
      let output = await this.$html2canvas(el, options);
      // console.log("this.output", this.output);
      if (this.info && this.info.platform == "web") {
        downloadDataURL(output, this.item.title);
      } else {
        this.openTab(output);
      }
    },
    openTab(data) {
      var image = new Image();
      image.src = data;

      var w = window.open("");
      w.document.write(image.outerHTML);
    },
  },
  async mounted() {
    this.info = await Device.getInfo();
  },
};
</script>

<style>
.rounded-corner {
  border-radius: 0.4rem;
}
.unapproved {
  box-shadow: inset 0px 0px 0px 1px rgba(255, 0, 0, 1);
}
</style>