<template>
  <DynamicScroller
    v-if="authUser && authUser.id"
    :items="items"
    :min-item-size="54"
    class="scroller px-0 mx-0"
  >
    <template #after @visible="log('after visible')">
      <v-card-title class="justify-center">
        <div v-intersect="endIntersect" />
      </v-card-title>
    </template>
    <template v-slot="{ item, index, active }">
      <DynamicScrollerItem
        :item="item"
        :active="active"
        :size-dependencies="[item.id]"
        :data-index="index"
        @visible="log('item visible')"
      >
        <post-card-info
          v-if="item.type == 'signup'"
          :item="item"
          @removed="(itm) => $emit('removed', itm)"
          @remove="handleRemove"
          @contextClicked="handleContextClick"
          class="mb-2 mt-0 px-2"
        />
        <post-card
          v-else
          :item="item"
          @removed="(itm) => $emit('removed', itm)"
          @remove="handleRemove"
          @contextClicked="handleContextClick"
        />
        <post-actions
          v-if="item.type != 'signup'"
          class="mb-2 mt-0 px-2"
          :item="item"
        />
      </DynamicScrollerItem>
    </template>
  </DynamicScroller>
</template>

<script>
import { mapGetters } from "vuex";

import bus from "@/bus";
import { axios } from "@/plugins/axios";
import PostCard from "../modules/PostCard";
import PostCardInfo from "../modules/PostCardInfo";
import PostActions from "../modules/PostActions";
export default {
  props: {
    items: Array,
    canLoadMore: Boolean,
  },
  components: { PostCard, PostCardInfo, PostActions },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
  methods: {
    log(msg) {
      console.log(msg);
    },
    handleRemove(item) {
      // if (!this.items.find((el) => el.id == item.id)) {
      //   return;
      // }
      if (
        !confirm(`Are you sure to delete ${item.title ? item.title : item.id}?`)
      ) {
        return Promise.reject("CANCELED_BY_USER");
      }
      bus.$emit("showWait");
      return axios
        .delete("feed/" + item.id)
        .then((res) => {
          this.$emit("removed", item);
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.$emit("removed", item);
            // this.items = this.items.filter((el) => el.id != item.id);
          }
          console.log("err", err.response);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    handleContextClick(item) {
      let name = "";
      let id = null;
      switch (item.type) {
        case "prep_attempt":
          name = "exams-mocktest-preview";
          id = item.data.mocktest_id;
          break;

        case "doubt":
          name = "doubt";
          id = item.data.id;
          break;

        case "doubt_answer":
          name = "doubt";
          id = item.data.doubt_id;
          break;

        case "prep_tag":
          name = "learn-questions";
          id = item.data.id;
          break;

        default:
          break;
      }

      this.$router.push({
        name: name,
        params: { id },
      });
    },

    endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting) {
        // let moreVendors = loadMoreFromApi()
        // this.vendors = [ ...this.vendors, ...moreVendors]
        // console.log("reached-end");
        this.$emit("reachedEnd");
      }
    },
  },
};
</script>

<style scoped>
.scroller {
  height: 100%;
}



.user {
  height: 32%;
  padding: 0 12px;
  display: flex;
  align-items: center;
}
.my-list {
  height: calc(100vh - 8rem);
}
</style>
