<template>
  <v-card-title
    height="calc(100vh - 200px)"
    class="d-flex justify-center align-center"
    v-if="items.length < 1"
  >
    {{ loading ? $lang("pleaseWait") : $lang("noItems") }}
  </v-card-title>
  <v-virtual-scroll
    v-else
    :items="items"
    :item-height="70"
    height="calc(100vh - 200px)"
  >
    <template v-slot:default="{ item }">
      <v-list-item>
        <!-- <v-list-item-avatar>
              <v-avatar color="red" contain size="56" class="white--text">
                <v-img :src="item.exams[0].banner_square" />
              </v-avatar>
            </v-list-item-avatar> -->

        <v-list-item-content>
          <v-list-item-title>{{
            isHindi ? item.title_hi : item.title
          }}</v-list-item-title>
          <v-list-item-subtitle class="overline">{{
            item.tags.map((el) => (isHindi ? el.title_hi : el.title)).join(", ")
          }}</v-list-item-subtitle>
          <v-list-item-subtitle class="caption">
            <v-chip
              x-small
              text
              v-for="exam in item.exams"
              :key="exam.id"
              @click="$router.push({ name: 'exam', params: { id: exam.id } })"
              >{{ isHindi ? exam.title_hi : exam.title }}</v-chip
            >
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="d-flex justify-center align-center">
          <v-btn
            @click="
              $router.push({
                name: 'exams-mocktest-preview',
                params: { id: item.id },
              })
            "
            depressed
            icon
            small
          >
            <v-icon> mdi-open-in-new </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </template>
  </v-virtual-scroll>
</template>

<script>
import { axios } from "@/plugins/axios";
export default {
  props: ["user_id"],
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  methods: {
    fetchUser() {
      this.loading = true;
      axios
        .get("social/stats/" + this.user_id + "/purchased/mocktests/")
        .then((res) => {
          this.items = res.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchUser();
  },
};
</script>

<style>
</style>