<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    transition="dialog-transition"
    v-if="item"
  >
    <v-card class="mt-1" outlined>
      <form @submit.prevent="handleFormSubmit()">
        <v-card-title>
          {{ $lang("SubmitAPost").ucWords() }}
          <v-spacer></v-spacer>
          <!-- <v-btn :loading="loading" icon @click="rtfOn = !rtfOn">
            <v-icon v-if="rtfOn">mdi-format-textbox</v-icon>
            <v-icon v-else>mdi-format-text</v-icon>
          </v-btn> -->
          <v-btn :loading="loading" icon @click="handleCancelClick">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <span v-if="$store.getters.getCurrLocale == 'en'"
            >Remember to keep your submitted content respectful, follow our
            <a target="_system" href="https://vikarn.com/community-guidelines">
              Community Guidelines</a
            >.</span
          >
          <span v-else>
            अपनी सबमिट की गई सामग्री को सम्मानजनक रखना याद रखें, हमारे
            <a
              target="_system"
              href="https://vikarn.com/community-guidelines/hindi"
              >समुदाय दिशानिर्देशों</a
            >
            का पालन करें।
          </span>
        </v-card-text>

        <v-card-text>
          <vue-tip
            :placeholder="$lang('TYPESOMETHING')"
            :initial="item.title"
            :autofocus="true"
            @changed="
              (val) => {
                item.title = val;
              }
            "
            :errors="errors && errors.title ? errors.title : []"
            :variant="rtfOn ? 'minimal' : 'none'"
          />

          <v-btn
            v-if="!showUploader && !item.image_url"
            @click="
              () => {
                showUploader = !showUploader;
              }
            "
            block
          >
            Add an image
          </v-btn>
          <upload-area
            v-if="showUploader"
            identity="feed"
            title="post"
            :width="1200"
            :show="showUploader"
            :submitProp="submitImageForUpload"
            @imageAdded="handleImageDataAdded"
            @uploaded="handleImageUploaded"
            @shown="
              (val) => {
                showUploader = val;
              }
            "
          />
          <v-img width="50" :src="item.image_url"></v-img>
        </v-card-text>
        <v-card-text>
          <span v-if="$store.getters.getCurrLocale == 'hi'">
            आपकी पोस्ट हमारे किसी मोडरेटर द्वारा स्वीकृत किए जाने तक पब्लिक को
            दिखाई नहीं देगी.
          </span>
          <span v-else>
            Your post will not be visible to public unless someone from our
            moderators team approves it.
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            block
            :loading="loading"
            type="submit"
            outlined
            text
            color="primary"
          >
            <v-icon left>mdi-comment</v-icon>
            {{ $lang("Submit") }}
          </v-btn>
          <v-spacer></v-spacer>
          <!-- <v-btn :loading="loading" @click="handleCancelClick" outlined text>
            <v-icon left>mdi-close</v-icon>
            {{ $lang("Cancel") }}
          </v-btn> -->
        </v-card-actions>
      </form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "@/bus";
import { axios } from "@/plugins/axios";
import VueTip from "@/components/VueTip";
import { stripScriptTags } from "@/helpers/utils";
import UploadArea from "@/components/UploadArea";
export default {
  name: "new-feed-post-dialog",
  components: { VueTip, UploadArea },
  props: ["show"],
  data() {
    return {
      rtfOn: false,
      item: {},
      loading: false,
      showUploader: false,
      dialog: null,
      hasDataInUploader: false,
      submitImageForUpload: false,
      pendingSubmit: false,
    };
  },
  watch: {
    show(val) {
      this.dialog = val;
    },
    dialog(val) {
      this.$emit("closed", val);
    },
  },
  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),

    errors: {
      get() {
        return this.$store.getters.getFormErrors;
      },
      set(_) {},
    },
  },
  methods: {
    handleImageUploaded(url) {
      this.showUploader = false;
      this.item.image_url = url;
      if (this.pendingSubmit) {
        this.doSubmit();
      }
    },
    handleImageDataAdded(status) {
      this.hasDataInUploader = status;
    },
    handleFormSubmit() {
      if (this.hasDataInUploader) {
        this.pendingSubmit = true;
        this.submitImageForUpload = true;
      } else {
        this.submitImageForUpload = false;
        this.pendingSubmit = false;
        this.doSubmit();
      }
    },
    doSubmit() {
      if (
        !this.item ||
        !this.item.title ||
        this.item.title.length < 1 ||
        this.item.title == "<p></p>"
      ) {
        this.$store.dispatch("setAlert", {
          message: this.$lang("TypeSomething"),
          type: "warning white--text",
        });
        return;
      }
      this.loading = true;
      bus.$emit("showWait");
      this.item.title = stripScriptTags(this.item.title);
      this.item.description = stripScriptTags(this.item.description);
      axios
        .post("feed", this.item)
        .then((res) => {
          // success
          this.$emit("submitted", res.data.data);
          this.init();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
          bus.$emit("hideWait");
        });
    },
    handleCancelClick() {
      this.init();
    },
    init() {
      this.item = {};
      this.dialog = false;
      this.showUploader = false;
      this.hasDataInUploader = false;
      this.submitImageForUpload = false;
      this.pendingSubmit = false;
    },
  },
  created() {},
};
</script>

<style>
.img-cls {
  width: 100px;
}
</style>