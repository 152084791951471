var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex py-5 mx-2",attrs:{"color":"transparent","elevation":"0","id":_vm.getElementID}},[_c('div',{staticClass:"grey--text d-flex flex-row text-left"},[(
        _vm.authUser &&
        ['superadmin', 'admin', 'manager', 'moderator'].includes(
          _vm.authUser.role
        ) &&
        !_vm.item.user.verified
      )?_c('v-badge',{attrs:{"fixed":"","top":"","left":"","dot":"","color":"red"}}):_vm._e(),_c('v-avatar',{staticClass:"mr-4",attrs:{"size":"44"}},[_c('v-img',{key:_vm.item.user ? _vm.item.user.avatar : _vm.item.id,staticClass:"image",attrs:{"src":_vm.item.user ? _vm.item.user.avatar : '',"alt":"avatar"},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
            name: 'profile',
            params: { id: _vm.item.user.username },
          })}}})],1),_c('div',[_c('div',{staticClass:"body-1"},[_vm._v(" "+_vm._s(_vm.item.user ? _vm.item.user.display_name : "user")+" has joined. ")]),_c('div',{class:_vm.$store.getters['isThemeDark']
            ? "caption grey--text text--darken-2"
            : "caption grey--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm._f("fromNow")(_vm.item.created_at))+" ")])])],1),_c('v-spacer'),(true)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$router.push({
        name: 'profile',
        params: { id: _vm.item.user.username },
      })}}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }