<template>
  <v-card-title
    height="calc(100vh - 200px)"
    class="d-flex justify-center align-center"
    v-if="!items || items.length < 1"
  >
    {{ loading ? $lang("pleaseWait") : $lang("noItems") }}
  </v-card-title>
  <v-row v-else dense no-gutters fill-height>
    <v-col
      cols="12"
      class="py-10 justify-center align-center d-flex fill-height"
    >
      Check Back Later
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["profile"],
  data() {
    return {
      items: [],
      loading: false,
    };
  },
};
</script>

<style>
</style>