var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.authUser && _vm.authUser.id)?_c('v-row',[_c('v-col',{staticClass:"d-flex my-4",attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"ma-0 pa-0 px-1 anim-shine",attrs:{"small":"","color":"","outlined":""},on:{"click":function () {
          _vm.$router.push({
            name: 'products',
          });
        }}},[_c('v-img',{staticClass:"mr-1",attrs:{"width":"20","src":"/img/coins/coin-single-50.png"}}),_c('span',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.authUser.wallet ? _vm.authUser.wallet.coins : "0"))]),_c('v-icon',{staticClass:"ml-0"},[_vm._v("mdi-plus")])],1),_c('v-spacer'),_c('v-btn',{staticClass:"rounded-xl",attrs:{"small":"","outlined":"","text":""},on:{"click":function () {
          _vm.$router.push({
            name: 'update-profile',
          });
        }}},[_vm._v(" "+_vm._s(_vm.$lang("update") + " " + _vm.$lang("profile"))+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-account")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }